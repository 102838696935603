@import '_variables.scss';
@import '~@launchpad/styles/style.scss';
@import '~@launchpad/styles/_mixins.scss';

@font-face {
  font-family: 'SamsungOne-200';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/SamsungOne-200_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-300';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/SamsungOne-300_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-400';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/SamsungOne-400_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-500';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/SamsungOne-500_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-600';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/SamsungOne-600_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-700';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/SamsungOne-700_v1.0.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'SamsungOne-800';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/SamsungOne-800_v1.0.ttf'); /* IE9 Compat Modes */
}

body {
  font-family: 'SamsungOne-500' !important;
}

.logo {
  @media (max-width: 1600px) {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (max-width: 1200px) {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (max-width: 768px) {
    left: auto;
    right: 5px;
    transform: translate(0, 0);
  }
  @media (max-width: 500px) {
    width: 100px !important;
  }
}

.top-bar {
  @include theme-aware('box-shadow', 'shadow');
  @media (max-width: 450px) {
    padding-right: 10px !important;
    // .profile-image-holder {
    //   display: none !important;
    // }
    .name-holder {
      display: none !important;
    }
    .top-bar-vertical-line {
      margin: 0 12px !important;
    }
  }
}

.black-btn {
  @include theme-aware('border', 'border-button');
  border-radius: 25.5px !important;
  outline: none !important;
  @include theme-aware('background', 'color-background');
  @include theme-aware('color', 'color-text');
  font-size: 16px !important;
  font-weight: 500;
  text-align: center;
  max-width: 308px;
  max-height: 51px;
  width: 100%;
  height: 100%;
  font-family: 'Proxima-Nova Normal' !important;
  text-transform: uppercase;
  span {
    @include theme-aware('color', 'color-text');
  }
  &:hover {
    @include theme-aware('background', 'color-background');
    @include theme-aware('box-shadow', 'shadow');
  }
  @media (max-width: 450px) {
    font-size: 14px !important;
  }
}

.input-group {
  label,
  i {
    @include theme-aware('color', 'color-text');
  }
  @include theme-aware('color', 'color-text');
}

.MuiInputBase-root {
  @include theme-aware('color', 'color-text');
}

.circle-progress {
  @include theme-aware('color', 'color-text');

  &.light {
    color: white !important;
  }
}

.dashboard-header {
  .balance-holder {
    .total-holder {
      h3 {
        @include theme-aware('color', 'color-text');
      }
      h1 {
        @include theme-aware('color', 'color-text');
      }
      h4 {
        @include theme-aware('color', 'color-text');
      }
    }
  }
}
.progress-text {
  @include theme-aware('color', 'color-text');
}

.send-load-btn-wrapper {
  @include theme-aware('color', 'color-text');

  cursor: pointer;
  &:hover {
    @include theme-aware('color', 'color-gray');
  }
}

.points-redeem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  @include theme-aware('border', 'border-main');
  border-radius: 30px;
  img {
    @include theme-aware('filter', 'filter-invert');
  }
  i {
    @include theme-aware('color', 'color-text');
  }
  &:hover {
    @include theme-aware('box-shadow', 'shadow');
  }
}

.lock-card-modal .progress-text {
  @include theme-aware('color', 'color-text');
}
.card-page-card-navigation ul {
  @include theme-aware('border-left', 'border-main');
  li {
    &:hover {
      i {
        @include theme-aware('color', 'color-gray');
      }
    }
    i {
      @include theme-aware('color', 'color-text');
    }
  }
}

.side-bar .sideMenuList li {
  &:hover {
    @include theme-aware('background', 'color-background');
    a,
    i,
    span {
      @include theme-aware('color', 'color-gray');
    }
  }
  a,
  i {
    @include theme-aware('color', 'color-text');
  }
}

.chart-buttons-wrapper span.active {
  @include theme-aware('background', 'color-gray');
}

.filter-container .btn-holder span.active {
  @include theme-aware('background', 'color-gray');
}

.fx-redeem-content {
  p {
    @include theme-aware('color', 'color-text');
  }
  .fx-redeem-input-holder p {
    @include theme-aware('color', 'color-text');
  }
}

.go-to-inbox {
  @include theme-aware('color', 'color-text');
}
.fx-redeem-content i {
  @include theme-aware('color', 'color-text');
}

.logo,
.side-bar .dash-logo {
  @include theme-aware('-webkit-filter', 'filter-invert');
  @include theme-aware('filter', 'filter-invert');
}

.login-overlay-container .logo {
  @include theme-aware('-webkit-filter', 'login-logo-invert');
  @include theme-aware('filter', 'login-logo-invert');
}

.screen-layout .logo {
  top: 5% !important;
  left: 15% !important;
}

.MuiPickersModal-dialogRoot {
  @include theme-aware('color', 'color-text');
  @include theme-aware('background', 'color-main');
}

.MuiPickersCalendarHeader-daysHeader {
  span {
    @include theme-aware('color', 'color-text');
  }
}
.MuiPickersCalendar-week {
  .MuiPickersDay-day {
    @include theme-aware('color', 'color-text');
  }
  .MuiPickersDay-daySelected {
    @include theme-aware('color', 'color-success');
  }
  .MuiPickersDay-dayDisabled {
    @include theme-aware('color', 'color-gray');
  }
}
