$ERROR_RED: #ff6e6e;
$GREEN_COLOR: #3fd184;
$MAIN_COLOR: #000000;
$GRAY_COLOR: #c2c2c2;
$CHART_START_COLOR: #000000;

$themes: (
  light: (
    color-background: #ffffff,
    color-main: #ffffff,
    color-main-darker: #000000,
    color-gray: #c2c2c2,
    color-success: #3fd184,
    color-error: #ff6e6e,
    color-text: #000000,
    border-main: 1px solid #000000,
    border-button: 2px solid #333333,
    border-disabled: 1px solid #c2c2c2,
    filter-invert: invert(0%),
    login-logo-invert: invert(100%),
    shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.24),
    shadow-side-bar: 4px 5px 11px 0 rgba(0, 0, 0, 0.24),
    last-transaction-linear-gradient:
      linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.6),
        #ffffff,
        #ffffff
      ),
    main-linear-gradient: linear-gradient(rgba(0, 0, 0, 0.5), #000000)
  ),
  dark: (
    color-background: #2d2c29,
    color-main: #000000,
    color-main-darker: #000000,
    color-gray: #c2c2c2,
    color-success: #3fd184,
    color-error: #ff6e6e,
    color-text: #ffffff,
    border-main: 1px solid #ffffff,
    border-button: 2px solid #ffffff,
    border-disabled: 1px solid #c2c2c2,
    filter-invert: invert(100%),
    login-logo-invert: invert(100%),
    shadow: 0 5px 20px 0 rgba(255, 255, 255, 0.24),
    shadow-side-bar: 4px 5px 11px 0 rgba(255, 255, 255, 0.24),
    last-transaction-linear-gradient:
      linear-gradient(
        rgba(45, 44, 41, 0.3),
        rgba(45, 44, 41, 0.6),
        #2d2c29,
        #2d2c29
      ),
    main-linear-gradient: linear-gradient(rgba(0, 0, 0, 0.5), #ffffff)
  )
);

:export {
  chart_start_color: $CHART_START_COLOR;
  gray_color: $GRAY_COLOR;
  main_color: $MAIN_COLOR;
  success_color: $GREEN_COLOR;
  error_color: $ERROR_RED;
}
